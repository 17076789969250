<template>
  <b-navbar-nav>
    <b-nav-item href="/">Inicio</b-nav-item>
    <!-- VENTAS -->
    <v-guard :permissions="['VENTAS']"><b-nav-item href="/ventas/index">Ventas</b-nav-item></v-guard> 
    <!-- COMPRAS -->
    <v-guard :permissions="['COMPRAS']"><b-nav-item href="/compras">Compras</b-nav-item></v-guard>
    <!--  ECOMMERCE -->
    <v-guard :permissions="['ECOMMERCE']"><b-nav-item href="/ecom/list">Ecommerce</b-nav-item></v-guard>
    <!--  INVENTARIO -->
    <v-guard :permissions="['INVENTARIO']"><b-nav-item href="/inventario/productos">Inventario</b-nav-item></v-guard>
    <!--  CAJAS -->
    <v-guard :permissions="['CAJAS']"><b-nav-item href="/cajas/index">Cajas</b-nav-item></v-guard>
    <!--  REPORTES -->
    <v-guard :permissions="['REPORTES']"><b-nav-item-dropdown text="Reportes" right>
      <h2>Ventas</h2>
      <v-guard :permissions="['REPORTE_VENTAS']"><b-dropdown-item href="/reports/sales">Vision General Ventas</b-dropdown-item></v-guard>
      <v-guard :permissions="['INVENTARIO']">
      <b-dropdown-item href="/reports/ranking">Ranking por producto</b-dropdown-item>
      </v-guard>
      <!--b-dropdown-item href="/reports/reportz">Reporte Z</b-dropdown-item-->
      <hr>
      <h2>Fiscal</h2>
      <v-guard :permissions="['REPORTE_IVA_VENTAS']"><b-dropdown-item href="/reports/ivasales">IVA ventas</b-dropdown-item></v-guard>
      <v-guard :permissions="['REPORTE_IVA_COMPRAS']"><b-dropdown-item href="/reports/ivapurchases">IVA compras</b-dropdown-item></v-guard>
      <hr>
      <h2>Compras</h2>
      <v-guard :permissions="['REPORTE_COMPRAS']"><b-dropdown-item href="/reports/purchases">Visión general Compras</b-dropdown-item></v-guard>
      <hr>
      <h2>Inventario</h2>
      <v-guard :permissions="['REPORTE_STOCK_MINIMO']"><b-dropdown-item href="/reports/stock">Stock mínimo</b-dropdown-item></v-guard>
      <v-guard :permissions="['REPORTE_STOCK_VALORIZADO']"><b-dropdown-item href="/reports/stockvalued">Stock valorizado</b-dropdown-item></v-guard>
      <hr>
      <h2>Fondos</h2>
      <v-guard :permissions="['REPORTE_MOVIMIENTO_FONDO_VALORIZADO']">
      <b-dropdown-item href="/reports/funds">Movimientos de caja</b-dropdown-item>
      <b-dropdown-item href="/reports/Consolidado">Consolidado Anual - Ventas vs. Egresos </b-dropdown-item>
      <b-dropdown-item href="/reports/Consolidated">Consolidado Anual - Cajas vs. Egresos</b-dropdown-item>
      </v-guard>
      <hr>
      <h2>Cuenta corrientes de clientes</h2>
      <v-guard :permissions="['CLIENTES_CTA_CORRIENTE']">
      <b-dropdown-item href="/reports/cccbalancestodate">Listado de saldos a la fecha</b-dropdown-item>
      </v-guard>
      <hr>
      <h2>Cuenta corrientes de proveedores</h2>
      <v-guard :permissions="['PROVEEDORES_CTA_CORRIENTE']">
      <b-dropdown-item href="/reports/pacbalancestodate">Listado de saldos a la fecha</b-dropdown-item>
      </v-guard>
      <hr>
      <h2>Vendedores</h2>
      <b-dropdown-item href="/reports/salesclerksranking" v-if="salesclerkModule === 1">Ranking de ventas</b-dropdown-item>
      <b-dropdown-item href="/reports/cccbysalesclerk" v-if="salesclerkModule === 1">Resumen de cuentas por vendedor</b-dropdown-item>
    </b-nav-item-dropdown></v-guard>
  </b-navbar-nav>
</template>

<script>
  export default {
    data() {
      return {
        salesclerkModule: 0
      }
    },
    mounted() {
      this.getAuthUser();
    },
    methods: {
      getAuthUser() {
        this.$http
          .get("/api/users/getAuthUser", {
            headers: { 
              Authorization: "Bearer " + localStorage.getItem("token")
            },
          })
          .then((response) => {
            this.salesclerkModule = response.data.data.branchoffices[0].options.salesclerk_module;
          })
      },
    },  
  }
</script>