<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
            <div class="modal-body">
                <slot name="body">
                    <div class="loading" v-if="loading">Loading&#8230;</div>
                    <div class="row d-flex justify-content-center">
                        <div class="col-md-4">
                            <label class="control-label">Remanente:</label>
                            <input class="form-control" type="text" v-model="remainder"> 
                        </div>
                    </div>
                    <div class="row d-flex justify-content-center">
                        <div class="col-md-4 mt-4">
                            <button type="button" class="btn btn-orange" @click="closeFund()">
                                Cerrar caja
                            </button>
                        </div>
                        <div class="col-md-4 mt-4">
                            <button type="button" class="btn btn-outline-primary" @click="close()">
                                Cancelar
                            </button>
                        </div>
                    </div>
                </slot>
            </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
    props: ['fund_id'],
    data: function() {
        return {
            loading:false,
            remainder:null,
            fund: [],
            balance: null,
            movement: {
                id: null,
                type: "out",
                amount: null,
                fund_id: this.fund_id,
                sale_id: null,
                coupon_id: null,
                tpcheck_id: null,
                purchase_id: null,
                category_id: null,
                cc_id: null,
                user_id: null,
                pac_id: null,
                category: null,
                origen: "",
                in: null,
                out: null,
            },
     
        }
    },
    mounted() {
        this.getTotal();
        this.getFund();
    },
    methods: {
        close(){
            this.$emit('closemodal');
        },
        getTotal() {
            var vm = this;
            this.$http.get("/api/funds/getBalance/" + vm.fund_id)
            .then((response) => {
                vm.balance = response.data.data;
                console.log(response.data);
            });
        },
        getFund(){
            var vm = this;
            this.$http.get("/api/funds/getFund/" + vm.fund_id)
            .then((response) => {
                vm.fund = response.data.data;
            });
        },
        closeFund(){
            var vm = this;
            var id = this.fund_id;
            if (confirm("¿Está seguro que desea cerrar esta caja?") == true) {
                vm.movement.amount = vm.balance - vm.remainder;
                vm.movement.category_id = 6;
                vm.movement.description = "CIERRE CAJA: En caja: " + vm.fund.currency + " " + vm.remainder;
                vm.movement.type = "out";
                
                this.$http
                .post("/api/funds/movement/" + this.fund_id, vm.movement)
                .then((response) => {
                    vm.$emit('closemodal');
                    vm.$emit('closeDone');
                });
            }
        }


	},
}
</script>

<style scoped>

.pricePanel{
    background-color: #F2F4F7;
    font-weight: bold;
    border:1px solid #d3d9e4;
    text-align: center;
    width: 216px;
    height: 38px;
    display: table-cell;
    vertical-align: middle;
}

.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

  .modal-mask {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    width: 50vw;
    margin: 0px auto;
    background-color: #fff;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    max-height: 90vh;
    overflow-y: auto;
  }

  .modal-header {
    display: flex;
    align-items: center;
  }

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .footer{
      display: flex;
      justify-content: flex-end;
      gap: 30px;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
</style>
