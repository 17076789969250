<template>
  <b-container fluid>
    <b-row class="mt-3">
      <b-col class="d-flex justify-content-start">
          <h4>Proveedores</h4>
      </b-col>
      <b-col class="text-right">
        <b-button @click="newProvider" variant="success" size="lg">Nuevo Proveedor + <i class="bi bi-truck"></i></b-button>
      </b-col>
    </b-row>
    <div class="row d-flex justify-content-between mt-2">
      <div class="col col-md-2">
        <!-- -------------------- BUSCADOR -------------------------- -->
        <input
          v-model="doSearch"
          type="search"
          placeholder="Buscar..."
          class="form-control"/>
      </div>
      <!-- -------------------- BTN EXPORT -------------------------- -->
      <div class="col col-md-1">
        <input type="button" @click="exportarCSV()" class="btn btn-md btn-outline-primary" value="Exportar CSV">
      </div>
    </div>
    <!-- ----------------------- TABLA -------------------------- -->
    <b-row>
      <b-col cols="md-12">
        <vdtnet-table
          :fields="fields"
          :opts="opts"
          :selectCheckbox=0
          @viewCC="viewCCMethod"   
          @edit="changeProviderId"
          @delete="deleteProvider"
          ref="table"
          />
      </b-col>      
      <b-col cols="md-4 mt-2">
        <NuevoProveedor
        :providerId="providerIdParent"
        v-if="showModal"
        @edit="changeProviderId"
        @confirmsave="refreshTable()"
        @close="showModal=false"
      />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import VdtnetTable from "vue-datatables-net";
import NuevoProveedor from "./Nuevo-proveedor.vue";

export default {
  components: {
    VdtnetTable,
    NuevoProveedor,
  },
  data: function () {
    return {
      showModal: false,
      showNewProvider: false,
      providerIdParent: null,
      doSearch: "",
      opts: {
        language: {
          url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
          },
        ajax: {
          url: process.env.VUE_APP_BASE_URL+"/api/providers/list",
          type: "GET",
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        },
        processing: true,
          pageLength: 15,
          searching: true,
          searchDelay: 100,
          destroy: true,
          ordering: true,
          lengthChange: true,
          serverSide: true,
          fixedHeader: true,
      },
      fields: {
        name: { label: "Nombre" },
        address: { label: "Dirección" },
        phone_1: { label: "Teléfono" },
        province: { label: "Prov"},
        city: { label:"Ciudad"},
        actions: {
          isLocal: true,
          label: "Acciones",
          defaultContent:
            '<div class="row divActions btn-group-sm">' +
            '<button data-action="viewCC" data-toggle="tooltip" title="Ver Cuenta Corriente" type="button" class="btn btn-light">CC</button>' +
            '<button data-action="edit" data-toggle="tooltip" title="Editar" type="button" class="btn btn-light marginButton"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">'+
            '<path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>'+
            '<path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>'+
            '</svg></button>' +
            '<button data-action="delete" data-toggle="tooltip" title="Eliminar" type="button" class="btn btn-light marginButton"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">'+
            '<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>'+
            '<path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>'+
            '</svg></button>' +
            "</div>",
        },
      },
    };
  },
  watch: {
      doSearch(newValue, oldValue) {
        if (newValue.length >= 4 || oldValue.length >= 4) {
          this.$refs.table.search(this.doSearch);
        }
        if (newValue === "" && oldValue.length > 0) {
          this.$refs.table.search(this.doSearch);
        }
      },
      selectedTypeFilter() {
        this.$refs.table.reload();
      },
    },
  methods: {  
    refreshTable() {
      var vm = this;
      if (vm.$refs.table !== undefined) vm.$refs.table.reload();
    },
    newProvider(){
      this.providerIdParent = null;
      this.showModal = true;
    },
    viewCCMethod(data) {
      this.$router.push({name: 'Detalle de cuenta corriente de proveedor', params: { providerId: data.id }})
    },
    changeProviderId(data) {
      this.providerIdParent = data.id;
      this.showModal= true;
    },
    deleteProvider(data) {
      if (confirm("¿Está seguro que desea borrar este proveedor?") == true) {
        this.$http
          .post("/api/providers/delete", { id: data.id })
          .then((response) => {
            
            if (response.data.code == 201) {
              this.refreshTable();
            }
          });
      }
    },    
    exportarCSV(){
      let vm=this;
      this.$http.post(process.env.VUE_APP_BASE_URL+"/api/providers/exportCSV")
        .then((response) => {
      });
    },
  },
  computed: {
  }  
};
</script>