<template>
  <b-row>
    <b-col>
      <!-- BUSQUEDA POR CODIGO -->
      <b-input-group v-if="!typeah" class="input-flex">
        <b-input-group-prepend>
          <b-button variant="info" @click="toggleTypeah">
            <i class="bi bi-search"></i>
          </b-button>
        </b-input-group-prepend>
        <b-form-input
          class="col-12"
          type="text" 
          ref="searchCodeBox" 
          v-model="code" 
          v-on:keyup="lookupProducts" 
          placeholder="Ingresa cod. de producto. Podes ingresar una cantidad. Por Ej. 3*1001">
        </b-form-input>
      </b-input-group>
      
      <!-- BUSQUEDA POR NOMBRE -->
      <b-input-group v-if="typeah" v-bind:class="[{'input-group': showBarcodes==true}]">
        <b-button v-if="showBarcodes" variant="info" @click="toggleTypeah">
          <i class="bi bi-upc-scan"></i>
        </b-button>
        
        
        <b-col>
          <typeahead
          :name="'product'"
          :placeh="'Buscar producto por nombre...'"
          :custom-class="'nameBox'"
          :clean-on="true"
          v-on:choosed="productChoosed"
          :default="product_default"
          :externalsrc="'/api/products/getProducts'"
          limit-chars="3"
          ref="searchNameBox"/>
        </b-col>
      </b-input-group>
    </b-col>
  </b-row>
</template>

<script>
import typeahead from '../global/Typeahead.vue';

export default {
  props: ['separator', 'showBarcodes', 'product_default', 'priceList'],
  data: function() {

    return {
      code: '',
      id_code: null,
      typeah: false
    }
  },
  mounted () {
      this.typeah = !this.showBarcodes;
      this.$refs.searchCodeBox.focus();
  },
  methods: {
    productChoosed(data){
      this.togglet();
      this.id_code = data.id;
      this.addProduct();
    },
    lookupProducts: function(e) {
      var app = this;
      if (this.typeah) return;
      if (this.showBarcodes && e.key === 'Enter' ) {
        app.addProductByCode();
      }
    },
    addProductByCode: function(){
      var app = this;
      var code = app.code.split(app.separator);
      var qu=(code.length !== 1) ? parseInt(code[0]) : 1;
      var co=(code.length !== 1) ? code[1] : code;

      if (co!=null) {
      window.axios.get('/api/products/getProductByCode/'+co+'/'+this.priceList).then(function(response) {
        if (response.data.code === 422) {
          alert(response.data.message + ' o se encuentra INACTIVO!');  
        }else {
          response.data.data.quantity = qu;
          response.data.data.discount = 0;
          app.code='';
          app.$emit('productadded',response.data.data);
        }
      });
      }
    },
    addProduct: function(){
      var app = this;

      if (app.id_code!=null && app.id_code>0) {
        window.axios.get('/api/products/getProduct/'+app.id_code+'/'+app.priceList).then(function(response) {
          response.data.data.quantity = 1;
          response.data.data.discount = 0;
          app.$emit('productadded',response.data.data);
        });
      app.id_code = null;
      }
    },
    togglet: function(){
      if (this.showBarcodes) this.toggleTypeah();
    },
    toggleTypeah() {
      var vm = this;
      this.typeah=!this.typeah;
      if (this.typeah)
        this.$nextTick(() => this.$refs.searchNameBox.$refs.nameBox.focus())
      else
        this.$nextTick(() => this.$refs.searchCodeBox.focus())
    }
  },
  components: {
    typeahead
  },
}
</script>

