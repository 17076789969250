<template>
  <b-container fluid
      :id="isNew ? 'newbranchoffice' : 'branchoffice'+newbranchoffice.id"
      
      :class="!isNew && first_child === newbranchoffice.id ? 'in' : ''">
    <b-card-body>
      <b-row>
        <b-col cols="md-4">
          <b-form-group >
            <label>Nombre de la Sucursal</label>
            <input name="name" type="text" class="form-control" v-model="newbranchoffice.name">
          </b-form-group>
          <b-form-group>
            <label class="control-label">CUIT</label>
            <input name="CUIT" placeholder="Ingresalo sin guiones" type="number" class="form-control" v-model="newbranchoffice.cuit" maxlength="13">
          </b-form-group>
          <b-form-group>
            <label class="control-label">Condición Fiscal</label>
            <b-form-select name="iva_type" class="form-control edited" v-model="newbranchoffice.iva_type">
              <option value="Otro">Selecciona tu condición fiscal</option>
              <option value="Responsable Inscripto">Responsable Inscripto</option>
              <option value="Exento">IVA Exento</option>
              <option value="Monotributo">Monotributista</option>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col cols="md-4">
          <b-form-group>
            <label class="control-label">Dirección*</label>
            <input id="address" name="address" type="text" class="form-control" required="" v-model="newbranchoffice.address">
          </b-form-group>
          <b-form-group>
            <label class="control-label">Ciudad*</label>
            <input id="city" name="city" type="text" class="form-control" required="" v-model="newbranchoffice.city">
          </b-form-group>
          <b-form-group>
            <label class="control-label">Provincia*</label>
            <b-form-select name="state" class="form-control" required="" v-model="newbranchoffice.state">
              <option value="Capital Federal">CABA</option>
              <option value="Buenos Aires">Buenos Aires</option>
              <option value="Catamarca">Catamarca</option>
              <option value="Chaco">Chaco</option>
              <option value="Chubut">Chubut</option>
              <option value="Corrientes">Corrientes</option>
              <option value="Cordoba">Córdoba</option>
              <option value="Entre Rios">Entre Ríos</option>
              <option value="Formosa">Formosa</option>
              <option value="Jujuy">Jujuy</option>
              <option value="La Pampa">La Pampa</option>
              <option value="La Rioja">La Rioja</option>
              <option value="Mendoza">Mendoza</option>
              <option value="Misiones">Misiones</option>
              <option value="Neuquen">Neuquen</option>
              <option value="Rio Negro">Río Negro</option>
              <option value="Salta">Salta</option>
              <option value="San Juan">San Juan</option>
              <option value="San Luis">San Luis</option>
              <option value="Santa Cruz">Santa Cruz</option>
              <option value="Santa Fe">Santa Fe</option>
              <option value="Santiago del Estero">Santiago del Estero</option>
              <option value="Tierra del Fuego">Tierra del Fuego</option>
              <option value="Tucuman">Tucumán</option>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col cols="md-4">
          <b-form-group>
            <label class="control-label">Ingresos Brutos</label>
            <input id="IIBB" name="IIBB" placeholder="Ingresalo sin guiones" type="number" class="form-control" v-model="newbranchoffice.iibb">
          </b-form-group>
          <b-form-group>
            <label class="control-label">Inicio de actividades</label>
            <input placeholder="Ingresalo de esta forma 21/05/2012" id="company_init_activities" name="startdate" type="text" class="form-control" v-model="startdate">
          </b-form-group>
          <b-form-group v-if="newbranchoffice.iva_type === 'Responsable Inscripto'">
            <label class="control-label">Razón Social</label>
            <input id="business_name" name="business_name" type="text" class="form-control" v-model="newbranchoffice.business_name">
          </b-form-group>
          <input id="country" name="company_country" type="hidden" value="Argentina">
          <input id="bo_id" name="id" type="hidden" :value="newbranchoffice.id">
        </b-col>
      </b-row>

      <hr style="margin-top: 5px" v-if="checkCompletedFields(newbranchoffice)">

      <b-row v-if="checkCompletedFields(newbranchoffice)">
        <b-col>
          <branch-offices-configs :bo_configs="newbranchoffice.configs" :bo_pos="newbranchoffice.salepoints" :priceslists="priceslists" :funds="funds" />
        </b-col>
      </b-row>
      <b-row v-if="checkCompletedFields(newbranchoffice)">
        <b-col cols="md-4">
          <branch-offices-options :bo_options="newbranchoffice.options" />
        </b-col>
        <b-col cols="md-4">
          <branch-offices-payment-methods :bo_paymentmethods="newbranchoffice.payments_methods" />
          <branch-offices-documents :bo_options="newbranchoffice.options"/>
        </b-col>
        <b-col cols="md-4">
          <div class="form-group" v-if="checkCompletedFields(newbranchoffice)">
            <branch-offices-templates :bo_configs="newbranchoffice.configs" />
          </div>
        </b-col>
      </b-row>

      <b-form-group>
        <b-button variant="orange" id="guardar" type="submit" v-on:click="save(newbranchoffice)" v-if="checkCompletedFieldsWithoutCountry(newbranchoffice)">{{ isNew ? 'Agregar' : 'Guardar' }}</b-button>
        <b-button variant="orange" id="guardar_disabled" type="submit" v-else disabled>{{ isNew ? 'Agregar' : 'Guardar' }}</b-button>
      </b-form-group>
    </b-card-body>
  </b-container>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import branchOfficesConfigs from './Branch-offices-configs.vue';
import branchOfficesOptions from './Branch-offices-options.vue';
import branchOfficesDocuments from './Branch-offices-documents.vue';
import branchOfficesPaymentMethods from './Branch-offices-paymentmethods.vue';
import branchOfficesTemplates from './Branch-offices-templates.vue';

export default {
  props: ['branchoffice', 'first_child', 'last_child', 'new', 'priceslists', 'funds'],
  data: function (f) {
    return {
      endpointurl: '/api/configs/',
      newbranchoffice: null,
      new_first_child: null,
      new_last_child: null,
      isNew: false,
      profile_image: null,
      startdate: null
    }
  },
  created() {
    this.newbranchoffice = this.branchoffice;
    this.new_last_child = this.last_child;
    this.new_first_child = this.first_child;
    this.isNew = this.new;
    this.startdate = (this.newbranchoffice.startdate == null || this.newbranchoffice.startdate == '0000-00-00') ? moment().format('DD/MM/YYYY') : moment(this.newbranchoffice.startdate, 'YYYY-MM-DD').format('DD/MM/YYYY');
  },
  methods: {
    moment: function () {
      return moment();
    },
    saleurl($bo_id) {
      return "frames['saleframe"+$bo_id+"'].print()"
    },
    snurl($bo_id) {
      return "frames['snframe"+$bo_id+"'].print()"
    },
    budgeturl($bo_id) {
      return "frames['budgetframe"+$bo_id+"'].print()"
    },
    checkCompletedFields(branchoffice) {
      return branchoffice && branchoffice.country != null;
    },
    checkCompletedFieldsWithoutCountry(branchoffice) {
      return branchoffice.address != null &&
          branchoffice.city != null &&
          branchoffice.cuit != null &&
          branchoffice.iibb != null &&
          branchoffice.iva_type != null &&
          branchoffice.name != null &&
          branchoffice.state != null;
    },
    handleProfileImage(e) {
      const files = e.target.files || e.dataTransfer.files;
      const id = this.newbranchoffice.id;
      if (files.length) {
        this.profile_image = files[0];
      }
      let reader = new FileReader();
      reader.onload = function() {
        let output = document.getElementById('logo_' + id);
        output.src = reader.result;
      };
      reader.readAsDataURL(files[0]);
    },
    save(branchoffice) {
      const app = this;
      branchoffice.startdate = moment(this.startdate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      const dataForm = new FormData();
      const profile = JSON.stringify(branchoffice);
      if (this.profile_image != null) {
        dataForm.append('file', this.profile_image);
      }
      dataForm.append('profile', profile);
      axios.post(app.endpointurl + 'branchoffice/info', dataForm)
      .then(function (response) {
        if (response.data.success) {
          
          if (!app.debug) {
            window.location.href = window.location.pathname;
          }
        } else {
          
        }
      });
    },
  },
  components: {
    branchOfficesPaymentMethods,
    branchOfficesConfigs,
    branchOfficesOptions,
    branchOfficesTemplates,
    branchOfficesDocuments
  }
}
</script>

