<template>
    <b-container fluid>
      <div class="row mt-3">
        <b-col class="d-flex justify-content-start">
          <h4>Clientes</h4>
        </b-col>
        <b-col class="text-right">
          <b-button @click="newClient" variant="success" size="lg">Nuevo Cliente <b-icon-person-plus></b-icon-person-plus></b-button>
        </b-col>
      </div>
      <div class="row d-flex justify-content-between">
        <b-col cols="md-2 text-left">
        <input
                  v-model="doSearch"
                  type="search"
                  class="form-control m-2"
                  placeholder="Buscar cliente..."
                  aria-controls="categories"
              />
        </b-col>
        <b-col cols="md-2 mt-3">  
          <div class="form-check form-check-inline">
            <input class="form-check-input" v-model="allClients" id="allClients" type="checkbox">
            <label class="form-check-label" for="allClients">&nbsp;Mostrar clientes inactivos</label>
          </div>     
        </b-col>
        <b-col cols="md-2 text-right mt-3">
          <input type="button" @click="exportarCSV()" class="btn btn-md btn-outline-primary" value="Exportar CSV">
        </b-col>
      </div>

      <b-row>
        <b-col cols="md-12">
          <vdtnet-table
            :fields="fields"
            :opts="opts"
            :selectCheckbox=0
            @viewHistory="viewHistoryMethod"
            @swithActive="swithActive"
            @viewCC="viewCCMethod"   
            @edit="changeClientId"
            @delete="deleteClient"
            ref="table"
          />
        </b-col>  
      </b-row>

      <b-col cols="md-12">
        <NuevoCliente 
          :clientId="clientIdParent"
          v-if="showModal"
          @edit="changeClientId"
          @confirmsave="refreshTable()"
          @close="showModal=false"
        />
      </b-col> 
    </b-container>

</template>

<script>
import VdtnetTable from "vue-datatables-net";
import NuevoCliente from "./Nuevo-cliente.vue";
import exportsMixin from "../../../mixins/exportsMixin";

export default {
  components: {
    VdtnetTable,
    NuevoCliente,
  },
  mixins: [exportsMixin],

  data: function () {
    return {
      showModal: false,
      doSearch: "",
      showNewClient: true,
      clientIdParent: null,
      allClients: false,
      opts: {
        language: {
          url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
          },
        ajax: {
          url: process.env.VUE_APP_BASE_URL+"/api/clients/list",
          data: (d) => {
              d.allClients = this.allClients
            },          
          type: "GET",
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        },
        processing: true,
          pageLength: 15,
          searching: true,
          searchDelay: 100,
          destroy: true,
          ordering: true,
          lengthChange: true,
          serverSide: true,
          fixedHeader: true,
      },
      fields: {
        name: { label: "Nombre" },
        cuit: { label: "CUIT" },
        phone_2: { label: "Celular" },
        actions: {
          isLocal: true,
          label: "Acciones",
          width: "200px",
          render: (data, type, client) => {
            const isConsumerFinal = client.name === "CONSUMIDOR FINAL";
            const isActive = client.active === 1;

            const icons = isConsumerFinal ?
              `<div class="row divActions btn-group-sm">
              </div>` :
              isActive ?
              `<div class="row divActions btn-group-sm"> 
                <button data-action="viewHistory" data-toggle="tooltip" title="Ver Historial" type="button" class="btn btn-light">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock-history" viewBox="0 0 16 16">
                    <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z"/>
                    <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z"/>
                    <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"/>
                  </svg>
                </button>
                <button data-action="swithActive" data-toggle="tooltip" title="Desactivar" type="button" class="btn btn-light ml-3">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="color:#e62626" class="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1z"/>
                  </svg>
                </button>
                <button data-action="viewCC" data-toggle="tooltip" title="Ver Cuenta Corriente" type="button" class="btn btn-light marginButton">
                  CC
                </button>
                <button data-action="edit" data-toggle="tooltip" title="Editar" type="button" class="btn btn-light marginButton">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                  </svg>
                </button>
                <button data-action="delete" data-toggle="tooltip" title="Eliminar" type="button" class="btn btn-light marginButton">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                  </svg>
                </button>
              </div>`:
              `<div class="row divActions btn-group-sm"> 
                  <button data-action="viewHistory" data-toggle="tooltip" title="Ver Historial" type="button" class="btn btn-light">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock-history" viewBox="0 0 16 16">
                      <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z"/>
                      <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z"/>
                      <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"/>
                    </svg>
                  </button>
                  <button data-action="swithActive" data-toggle="tooltip" title="Activar" type="button" class="btn btn-light ml-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="color:green" class="bi bi-check-square-fill" viewBox="0 0 16 16">
                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
                    </svg>
                  </button>
                  <button data-action="viewCC" data-toggle="tooltip" title="Ver Cuenta Corriente" type="button" class="btn btn-light marginButton">
                    CC
                  </button>
                  <button data-action="edit" data-toggle="tooltip" title="Editar" type="button" class="btn btn-light marginButton">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                      <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                    </svg>
                  </button>
                  <button data-action="delete" data-toggle="tooltip" title="Eliminar" type="button" class="btn btn-light marginButton">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                      <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                    </svg>
                  </button>
                </div>`;

            return icons;                         
          },
        },
      },

    }
  },
  methods: {  
    refreshTable() {
      var vm = this;
      if (vm.$refs.table !== undefined) vm.$refs.table.reload();
    }, 
    viewHistoryMethod(data) {
      this.$router.push({name: 'Historial de ventas del cliente', params: { clientId: data.id }})
    },
    viewCCMethod(data) {
      this.$router.push({name: 'Detalle de cuenta corriente de cliente', params: { clientId: data.id }})
    },
    changeClientId(data) {
      this.clientIdParent = data.id;
      this.showModal= true;
    },
    newClient(){
      this.clientIdParent = null;
      this.showModal = true;
    },
    swithActive(data){
      let vm = this;
      let outdata={'id' : data.id,};
      if(confirm("¿Está seguro que cambiar el estado de este cliente?") == true){
        this.$http.post("/api/clients/switchactive", outdata)
        .then(response => {
          if(response.data.success){
            vm.refreshTable();
          }
        })
        .catch(error => {
          alert(error);
        })
      }
    },
    deleteClient(data) {
      if (confirm("¿Está seguro que desea borrar este cliente?") == true) {
        this.$http
          .post("/api/clients/delete", { id: data.id })
          .then((response) => {
            
            if (response.data.code == 201) {
              this.refreshTable();
            }
          });
      }
    },
    exportarCSV(){
      this.$http.post(process.env.VUE_APP_BASE_URL+"/api/clients/exportClientListCSV")
        .then((response) => {
      });
    },
  },

  watch: {
    doSearch(newValue, oldValue) {
      if (newValue.length >= 4 || oldValue.length >= 4) {
        this.$refs.table.search(this.doSearch);
      }
      if (newValue === "" && oldValue.length > 0) {
        this.$refs.table.search(this.doSearch);
      }
    },
    allClients() {
        this.refreshTable()
      },
  },

  computed: {
    
  }  
};
</script>

