<template>
  <div class="card mb-4">
    <div class="card-header">
      <h6 class="card-title" v-if="edit == false">Nueva Caja</h6>
      <h6 class="card-title" v-else>Editar Caja</h6>
    </div>
    <div class="card-body">
      <b-form @submit.prevent>
        <b-form-group>
          <label class="control-label" for="input-live">Nombre:</label>
          <b-form-input
            class="form-control"
            id="input-live"
            v-model="fund.name"
            aria-describedby="input-live-help input-live-feedback"
            placeholder="Nombre de la caja"
            trim
          ></b-form-input>
          <b-form-text id="input-live-help">Campo obligatorio</b-form-text>
        </b-form-group>
        <b-form-group>
          <label class="control-label" for="input-live">Tipo de caja:</label>
          <b-form-select
            class="form-control"
            v-model="fund.type"
            :options="optionsUnit"
          ></b-form-select>
        </b-form-group>
        <b-form-group>
          <label class="control-label" for="input-live">Moneda:</label>
          <b-form-select
            class="form-control"
            v-model="fund.currency"
            :options="optionsCurrency"
          ></b-form-select>
        </b-form-group>
        <b-form-group>
          <label class="control-label" for="input-live"
            >Saldo de inicio:</label
          >
          <b-form-input
            class="form-control"
            id="input-live"
            v-model="fund['initial_amount']"
            aria-describedby="input-live-help input-live-feedback"
            trim
          ></b-form-input>
        </b-form-group>
      </b-form>
    </div>
    <div class="card-footer">
      <a @click="saveFund()"
      href="#import_funds"
      data-toggle="tab">
        <b-button v-if="edit == false" variant="orange" size="lg">Agregar caja</b-button>
        <b-button v-else variant="orange" size="lg">Modificar caja</b-button>
      </a>
    </div>
  </div>
</template>

<script>
export default {
props: ["fundId"],
data() {
  return {
    fund: {
      id: null,
      name: "",
      currency: "",
      notes: null,
      type: "",
    },

    emptyFund: {
      id: null,
      name: "",
      currency: "",
      notes: null,
      type: "Banco",
    },
    movement: {
      id: null,
      type: "in",
      amount: null,
      notes: "Inicio de Caja",
      fund_id: null,
    },

    selected: null,
    edit: false,

    optionsUnit: [
      { value: "1", text: "Banco" },
      { value: "0", text: "Efectivo" },
      { value: "2", text: "Tarjeta de credito" },
    ],
    optionsCurrency: [
      { value: "$", text: "Pesos" },
      { value: "US$", text: "Dolares" }
    ],
  };
},
  watch: {
    fundId: function (value) {
      if (value != null) {
        this.getFund(value);
      } else {
        this.resetForm();
      }
    },
  },
  mounted() {
    this.resetForm();
  },
  methods: {
    saveFund() {
      var vm = this;
      var url = this.fundId == null ? '/api/funds/add':'/api/funds/edit/'+this.fundId;
      this.$http.post(url, vm.fund)
      .then((response) => {
        vm.resetForm();
        vm.$emit("confirmsave");
      });
    },

    getFund(fundId){
      var vm = this;
      vm.edit = true;
      axios.get("/api/funds/getFund/" + fundId)
      .then((response) => {
        if (response.status==200) {
          let result = response.data.data;
          vm.fund.id = result.id;
          vm.fund.name = result.name;
          vm.fund.currency = result.currency
          vm.fund.type = result.type;
        }
      }).catch((error) => {
        console.log('error: '+error);
      })
    },

    resetForm() {
      this.edit = false;
      this.fund = Object.assign({}, this.emptyFund);
    },
  },
};
</script>
